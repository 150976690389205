<template>
  <section class="partners" :class="{
    'ja': $i18n.locale == 'ja'
  }">
    <p class="text fade-in">
      {{ $t("home.partners.text") }}
    </p>
    <span class="separator fade-in"></span>
    <h2 class="title">{{ $t("home.partners.subTitle") }}</h2>
    <div class="logo-row fade-in">
      <div class="logo-cell animation-third">
        <img
          src="images/youtube-promo-lp/partners/Universal_Logo_black.svg"
          alt="Universal Music Group"
        />
      </div>

      <div class="logo-cell animation-second">
        <img
          src="images/youtube-promo-lp/partners/Shiseido_Logo_black.svg"
          alt="SHISEIDO"
        />
      </div>

      <div class="logo-cell animation-first">
        <img
          src="images/youtube-promo-lp/partners/Kao_Logo_black.svg"
          alt="KAO"
        />
      </div>

      <div class="logo-cell animation-first">
        <img
          class="mitsubishi-logo"
          src="images/youtube-promo-lp/partners/Mitsubushi_Logo_black.svg"
          alt="MITSUBISHI"
        />
      </div>

      <div class="logo-cell animation-second">
        <img
          src="images/youtube-promo-lp/partners/Pepsi_Logo_black.svg"
          alt="Pepsi Logo"
        />
      </div>

      <div class="logo-cell animation-third">
        <img
          src="images/youtube-promo-lp/partners/Warner_Logo_black.svg"
          alt="Warner Logo"
        />
      </div>
    </div>
  </section>
</template>
<script>
export default {
  created() {
    window.addEventListener('scroll', this.fadeIn);
  },
  destroyed() {
    window.removeEventListener('scroll', this.fadeIn);
  },
  methods: {
    fadeIn() {
      const elements = document.getElementsByClassName('fade-in');
      
      for(let i = 0; i < elements.length; i++)
      {
        const el = elements[i];

        if(el.classList.contains("active")) 
          continue;

        const isActive =
          this.getCoords(el).top <= this.getWindowTop() + window.innerHeight;

        if (!el.classList.contains("active") && isActive) {
          el.classList.add("active");
        }
      } 
    },
    getWindowTop() {
      return window.pageXOffset === undefined ? document.documentElement.scrollTop : window.pageYOffset;
    },
    getCoords(elem) {
      const box = elem.getBoundingClientRect();

      const body = document.body;
      const docEl = document.documentElement;

      const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      const scrollLeft =
        window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

      const clientTop = docEl.clientTop || body.clientTop || 0;
      const clientLeft = docEl.clientLeft || body.clientLeft || 0;

      const top = box.top + scrollTop - clientTop;
      const left = box.left + scrollLeft - clientLeft;

      return { top: Math.round(top), left: Math.round(left) };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../../scss/app";

$fadeDuration: .7s;

.partners {
  position: relative;
  background-color: #F5F5F5;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20vh 0;
  color: #1d1f1f;
}

.text {
  width: 65%;
  line-height: 1.68em;
  @include font-size(21px);
  font-size: calc(21px + 15 * ((100vw - 1280px) / 1280));
  text-align: center;
  font-family: $family-light;
  will-change: opacity;
  @include opacity(0);

  &.active {
    @include opacity(1);
    @include transition(opacity ease $fadeDuration);
  }
}

.title {
  color: #1d1f1f;
  font-size: 2.2vw;
  letter-spacing: 0.015em;
  margin-bottom: 1.02em;
  will-change: transform;
  padding-bottom: 2vw;
  font-family: $family-bold;
  will-change: opacity;
  font-weight: 600;
}

.separator {
  display: block;
  @include linear-gradient(45deg, #d64472, #4923d8);
  border-radius: 1px;
  margin: 64px auto;
  height: 4px;
  backface-visibility: hidden;
  width: 32px;
  opacity: 0;
  will-change: opacity, width;
  @include opacity(0);

  &.active {
    width: 64px;
    opacity: 1;

    @include opacity(1);
    @include transition(all ease $fadeDuration);
  }
}

.logo-row {
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    .logo-cell img {
      @include opacity(1);
      @include translateY(0);
    }
  }
}

.logo-cell {
  width: 210px;
  padding: 30px;
  text-align: center;
  overflow: hidden;

  &.animation {
    &-first img {
      @include transition(all ease 1s);
      @include transition-delay($fadeDuration / 6);
    }
    &-second img {
      @include transition(all ease 1s);
      @include transition-delay(($fadeDuration / 6) * 2);
    }
    &-third img {
      @include transition(all ease 1s);
      @include transition-delay(($fadeDuration / 6) * 3);
    }
  }

  img {
    display: inline-block;
    width: 100%;
    @include opacity(0);
    @include translateY(-50px);
    will-change: opacity;
  }

  .mitsubishi-logo {
    height: 80px;
    width: auto;
  }
}

@media (max-width: 1025px) {
    .logo-row {
      flex-wrap: wrap;
    }
    .logo-cell {
      width: 26%;
    }
}

@media (max-width: 992px) {
    .partners {
        padding: 10vh 5vw;
        min-height: auto;
    }

    .text {
        width: 95%;
        @include font-size(17px);
    }

    .logo-row {
      flex-wrap: wrap;
    }

    .logo-cell {
        width: 33.3333%;
      
      img {
        @include opacity(1);
        @include translateY(0);
      }

      &.animation {
        &-first img {
          @include transition(all ease 1s);
          @include transition-delay($fadeDuration / 6);
        }
        &-second img {
          @include transition(all ease 1s);
          @include transition-delay($fadeDuration / 6);
        }
        &-third img {
          @include transition(all ease 1s);
          @include transition-delay($fadeDuration / 6);
        }
      }
    }
}

@media (max-width: 576px) {

    .title {
        @include font-size(20px);
        font-size: calc(20px + 4 * ((100vw - 375px) / 649));
    }

    .logo-cell {
        width: 50%;
    }
}
</style>

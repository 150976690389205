<template>
  <div class="wrrapper">
    <cookies-popup />
    <navigation :isWhite="isWhiteNav" />
    
    <div :style="{
      visibility: isSenaCanaOn ? 'visible' : 'hidden'
    }" class="image-bg" style="background-image: url(/images/home/home-client-bg-min.png);"></div>
    
    <div class="content-page-wrapper">
      <header2 id="home-header-section"/>
      <videoSection id="home-video-section"/>
      <digitalSection id="home-digital-section"/>
      <partners id="home-partners-section"/>
      <services id="home-services-section"/>
      <client id="home-clients-section" />
      <footerPage />
    </div>
  </div>
</template>

<script>
import navigation from './../components/navigation'
import footerPage from './../sections/footer'
import videoSection from './../sections/home/video'
import services from './../sections/home/services'
import client from './../sections/home/client'
import digitalSection from './../sections/home/new-digital'
import header2 from './../sections/home/header-carousel'
import partners from './../sections/home/partners'
import cookiesPopup from './../components/cookies-popup';

export default {
    data() {
      return {
        sectionsSettings: [
          {
            domId: "home-header-section",
            isNavWhite: true,
            isSenaCanaOn: false
          },
          {
            domId: "home-video-section",
            isNavWhite: false,
            isSenaCanaOn: false
          },
          {
            domId: "home-digital-section",
            isNavWhite: true,
            isSenaCanaOn: false
          },
          {
            domId: "home-partners-section",
            isNavWhite: false,
            isSenaCanaOn: false
          },
          {
            domId: "home-services-section",
            isNavWhite: true,
            isSenaCanaOn: true
          },
          {
            domId: "home-clients-section",
            isNavWhite: false,
            isSenaCanaOn: true
          }
        ],
        isWhiteNav: true,
        windowOffsetTop: 0,
        pageHeight: 0,
        isSenaCanaOn: false,
        
        isVideoPlay: false,
        navElement: null,

        mobileWidth: 793,
        tabletWidth: 992
      }
    },
    metaInfo: {
      title: 'D&D Promotion - Home'
    },
    components: {
      navigation, 
      videoSection, 
      digitalSection,
      services, 
      client, 
      footerPage, 
      header2,
      partners,
      cookiesPopup
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('scroll', this.homeScrollOverHandler);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('scroll', this.homeScrollOverHandler);
    },
    mounted() {

      this.$http({ url: 'setting/6', method: 'GET'})
        .then((res) => {
          this.isSenaKanaEnabled = +res.data.data.value;
        });

      this.navElement = document.querySelector(".nav");

      const body = document.body, html = document.documentElement;

      this.pageHeight = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
    
      document.getElementById("dnd-video").onended = () => this.isVideoPlay = false;

      if(this.isMobile() || this.isTablet()) {
        this.isWhiteNav = true;
      }
    },
    methods: {
      homeScrollOverHandler() {
        const windowTop = this.windowOffsetTop;
        
        for(let i = 0; i < this.sectionsSettings.length; i++) {

          const section = this.sectionsSettings[i];

          if(!section) {
            continue;
          }
          
          const el = document.getElementById(section.domId);

          if(el.offsetTop <= windowTop
              && (el.offsetTop + el.offsetHeight) >= windowTop) {

              this.isSenaCanaOn = section.isSenaCanaOn;
              this.isWhiteNav = section.isNavWhite;
          }
        }

        if(this.isMobile() || this.isTablet()) {
           this.isWhiteNav = true;
        }
      },
      handleScroll() {
         this.windowOffsetTop = window.pageXOffset === undefined ? document.documentElement.scrollTop : window.pageYOffset;
      },
      getVSFATriggerOffset() {
        return this.getPers(document.querySelector(".video-section").offsetTop);
      },
      getServiceSectionTriggerOffset() {
        return this.getPers(document.querySelector(".services-section").offsetTop);
      },
      getBlogTriggerOffset() {
        return this.getPers(document.querySelector(".blog-section").offsetTop);
      },
      getPers(height) { 
        return height / (this.pageHeight / 100)
      },
      changeNavToWhite(evt, el, windowTop) {
        
        if((el.offsetTop - this.navElement.offsetHeight) <= windowTop && (el.offsetTop + el.offsetHeight) >= windowTop) {
          this.isWhiteNav = true;
        }

        if(this.isMobile() || this.isTablet()) {
           this.isWhiteNav = true;
        }
      },
      changeNavToBlack(evt, el, windowTop) {
        if((el.offsetTop - this.navElement.offsetHeight) <= windowTop && (el.offsetTop + el.offsetHeight) >= windowTop) {
          this.isWhiteNav = false;
        }

        if(this.isMobile() || this.isTablet()) {
           this.isWhiteNav = true;
        }
      },
      isMobile() {
        return this.getWindowWidth() <= this.mobileWidth;
      },
      isTablet() {
        return this.getWindowWidth() >= this.mobileWidth && this.getWindowWidth() <= this.tabletWidth;
      },
      getWindowWidth() {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      }
    },
    watch: {
      windowOffsetTop() {
        const windowOffcetTop = this.getPers(this.windowOffsetTop);
        const videoSection = this.getVSFATriggerOffset();

        this.isShownFontAnimation = windowOffcetTop > videoSection - 10;
      }
    }
  }
</script>

<template>
    <section class="blog-section" :class="{ 'ja': $i18n.locale == 'ja' }">
        <router-link v-if="$i18n.locale != 'ja'" to="/youtube" class="content" :class="{ shown: isBlogShown }">
            <h3>{{ $t('home.clientTitle') }}</h3>
            <h2>{{ $t('home.clientText', { price: packagePrice }) }}</h2>
        </router-link>

        <router-link v-if="$i18n.locale == 'ja'" to="/youtube" class="content content-ja" :class="{ shown: isBlogShown }">
            <p class="ja-top-title">{{ $t("home.client.topTitle") }}</p>
            <p class="ja-top-text">{{ $t("home.client.topText", { price: packagePrice }) }}</p>
            <p class="ja-center-text"><span>{{ $t("home.client.centerText") }}</span>{{ $t("home.client.centerText2") }}</p>
            <p class="ja-bottom-text">{{ $t("home.client.bottomText") }}</p>
            <p class="ja-footer-block">{{ $t("home.client.footer") }}</p>
        </router-link>
    </section>
</template>

<script>
import { minimumPackage } from './../../mixins/minimum-package.mixin';
    export default {
        data() {
            return {
                windowOffsetTop: 0,
                pageHeight: 0,
                isBlogShown: false
            }
        },
        mixins:[minimumPackage],
        created () {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
        },
        mounted() {
            const body = document.body, html = document.documentElement;

            this.pageHeight = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
        },
        methods: {
            handleScroll() {
                this.windowOffsetTop = window.pageXOffset === undefined ? document.documentElement.scrollTop : window.pageYOffset;
            },
            getBlogTriggerOffset() {
                return this.getPers(document.querySelector(".blog-section").offsetTop);
            },
            getPers(height) { 
                return height / (this.pageHeight / 100)
            }
        },
        watch: {
            windowOffsetTop() {
                this.isBlogShown = this.getPers(this.windowOffsetTop) > this.getBlogTriggerOffset() - 5;
            }
        }
    }
</script>

<style lang="scss" scoped>
@import './../../scss/app';

.blog-section {
    padding: 5vw 0 5vw 11vw;
    position: relative;
}

.content {
    text-decoration: none;
    width: 35vw;
    height: 55vh;
    min-width: 410px;
    backdrop-filter: saturate(180%) blur(24px);
    background-color: rgba(25, 26, 26, 0.82);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 4vw;
    border-radius: 3px;
    @include transition(all .8s);
    @include transform(scale(.8));
    @include opacity(0);

    &.shown {
        @include transform(scale(1));
        @include opacity(1);
    }

    h3 {
        color: #F5F5F5;
        text-transform: uppercase;
        letter-spacing: 0.32em;
        font-weight: 600;
        font-size: 1.1vw;
        line-height: 17px;
        font-family: $family-bold;
    }

    h2 {
        color: #ffffff;
        line-height: 1.7em;
        font-weight: 600; 
        font-family: $family-medium;
        font-size: 30px;
        font-size: calc(30px + 14 * ((40vw - 769px) / 911));
        line-height: 1.7em;
    }

    &:hover {
        background-color: rgba(73,35,216,1);
        background-color: #F6BD25;
    }
}

.content-ja {
    padding: 2vw;
    padding-top: 1vw;
    height: 50vh;
    width: 33vw;
    
    p {
        font-weight: 600; 
        font-family: $family-medium;
        color: #ffffff;
        font-size: 1.7vw;
    }

    .ja-top-title {
        font-family: $family-light;
        font-weight: 400; 
        font-size: 1.35vw;
        padding-bottom: 50px;
        letter-spacing: -1px;
    }

    .ja-top-text {
        font-size: 1.7vw;
        position: relative;
        bottom: -1vw;
    }

    .ja-center-text {
         font-size: 1.7vw;

         span {
            font-size: 5vw;
            font-weight: 900;
            @include transition(all .8s);
         }
    }

    .ja-bottom-text {
        font-size: 2.4vw;
        position: relative;
        top: -1vw;
    }

    .ja-footer-block {
        padding-bottom: 100px;
        position: relative;
        top: -1.5vw;
    }

    &:hover {
        background-color: rgba(73,35,216,1);
        background-color: #F6BD25;

        .ja-center-text span {
            color: red;
             @include transition(all .8s);
        }   
    }
}

@media (max-width: 992px) {
    .blog-section {
        padding: 40vh 5vw 5vw;
    }
    
    .content {
        height: 40vh;
        width:auto;

        h3 {
            font-size: 2vw;
        }

        h2 {
            @include font-size(30px);
            padding-top: 0;
            line-height: 35px;
        }
    }

    .content-ja { 
        height: 50vh;

        p {
            font-weight: 600; 
            font-family: $family-medium;
            color: #ffffff;
            font-size: 4.7vw;
        }
        .ja-top-title {
            font-size: 4.35vw;
        }

        .ja-top-text {
            font-size: 4.7vw;
        }
            
        .ja-center-text {
         font-size: 4.7vw;

            span {
                font-size: 11vw;
            }
        }

        .ja-bottom-text {
            font-size: 5.4vw;
        }
    }
}

@media (max-width: 576px) {

    .content {
        width: auto;
        min-width: auto;
        padding: 32px;
        height: 40vh;
        min-height: 300px;

        h3 {
            font-size: 3vw;
        }
        
        h2 {
            @include font-size(24px);
            padding-top: 0;
            line-height: 30px;
        }
    }

    .ja .content h2 {
        @include font-size(27px);
    }
}
  
</style>
<template>
  <section class="video-section">
    <div class="title-part">
      <p class="header-text">
        {{ $t("home.videoText") }}
      </p>
    </div>
    <div class="video-body">
      <div class="video-wrapper">
        <div
          class="button-play"
          v-bind:style="{ display: !isVideoPlay ? 'flex' : 'none' }"
          v-on:click="isVideoPlay = !isVideoPlay"
        >
          <svg
            width="144"
            height="197"
            viewBox="0 0 144 197"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="a">
                <stop stop-color="#2C2D2E" offset="0%"></stop>
                <stop stop-color="#2C2D2E" offset="25%"></stop>
                <stop stop-color="#2C2D2E" offset="50%"></stop>
                <stop stop-color="#2C2D2E" offset="75%"></stop>
                <stop stop-color="#2C2D2E" offset="100%"></stop>
              </linearGradient>
            </defs>
            <path
              d="M698.3126 4158.8856l75.6933 63.4739c12.0608 10.1138 13.6392 28.0898 3.5254 40.1506a28.5004 28.5004 0 0 1-3.4303 3.4453l-75.6933 64.0381c-12.0166 10.1663-29.9994 8.6663-40.1656-3.3502a28.5 28.5 0 0 1-6.7421-18.4077v-127.512c0-15.7401 12.7599-28.5 28.5-28.5a28.5 28.5 0 0 1 18.3126 6.662z"
              transform="translate(-646 -4146)"
              stroke="url(#a)"
              stroke-width="12"
              fill="none"
              fill-rule="evenodd"
            ></path>
          </svg>
        </div>
        <div
          class="video-parallax-wrapper"
          v-bind:style="{ display: !isVideoPlay ? 'block' : 'none' }"
        >
          <img src="/images/home/Video_background.jpg" />
        </div>
        <div
          v-bind:style="{ display: isVideoPlay ? 'block' : 'none' }"
          class="video-item-wrapper"
        >
          <div
            class="wistia_responsive_padding"
            style="padding:56.25% 0 0 0;position:relative;"
          >
            <div
              class="wistia_responsive_wrapper"
              style="height:100%;left:0;position:absolute;top:0;width:100%;"
            >
              <div
                id="dnd-video"
                class="wistia_embed wistia_async_kcdl3t0llf videoFoam=true"
                style="height:100%;position:relative;width:100%"
              >
                <div
                  class="wistia_swatch"
                  style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                >
                  <img
                    src="https://fast.wistia.com/embed/medias/kcdl3t0llf/swatch"
                    style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                    alt=""
                    aria-hidden="true"
                    onload="this.parentNode.style.opacity=1;"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      isVideoPlay: false,
      isShownFontAnimation: false,
      isShownFontAnimation2: false,
      isShownFontAnimation3: false,
      isShownFontAnimation4: false,
      fontAnimationDuration: 200,
      windowOffsetTop: 0,
      pageHeight: 0
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    setTimeout(() => (this.isWordAvtive = true), 3000);

    const body = document.body,
      html = document.documentElement;

    this.pageHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
  },
  methods: {
    handleScroll() {
      this.windowOffsetTop =
        window.pageXOffset === undefined
          ? document.documentElement.scrollTop
          : window.pageYOffset;
    },
    getVSFATriggerOffsetH2() {
      return this.getPers(
        document.querySelector(".video-section .title-part").offsetTop
      );
    },
    getVSFATriggerOffset() {
      return this.getPers(document.querySelector(".video-section").offsetTop);
    },
    playVideo() {
      var video = window.Wistia.api("dnd-video");
      video.play();
    },
    videoStop() {
      document.getElementById("dnd-video").pause();
    },
    getPers(height) {
      return height / (this.pageHeight / 100);
    }
  },
  watch: {
    isVideoPlay() {
      if (this.isVideoPlay) {
        this.playVideo();
      } else {
        this.videoStop();
      }
    },
    windowOffsetTop() {
      if (this.isShownFontAnimation === false) {
        const windowOffcetTop = this.getPers(this.windowOffsetTop);
        const videoSection = this.getVSFATriggerOffset();

        this.isShownFontAnimation = windowOffcetTop > videoSection - 10;

        if (this.isShownFontAnimation === true) {
          setTimeout(
            () => (this.isShownFontAnimation2 = true),
            (700 / 50) * this.$t("home.videoTitleRow1").length
          );

          setTimeout(
            () => (this.isShownFontAnimation3 = true),
            (700 / 50) *
              (this.$t("home.videoTitleRow1").length +
                this.$t("home.videoTitleRow2").length)
          );

          setTimeout(
            () => (this.isShownFontAnimation4 = true),
            (700 / 50) *
              (this.$t("home.videoTitleRow1").length +
                this.$t("home.videoTitleRow2").length + 
                this.$t("home.videoTitleRow3").length)
          );
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./../../scss/app";

.header-text {
  width: 65%;
  line-height: 1.68em;
  @include font-size(21px);
  font-size: calc(21px + 15 * ((100vw - 1280px) / 1280));
  text-align: center;
}

.video-section {
  position: relative;
  background-color: #F5F5F5;

  .title-part {
    padding: 8vw 15vw 5vw;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      width: 100%;
      font-size: 1.8vw;
      color: #3d4040;
      line-height: 1.56em;
      font-family: $family-light;
      margin-bottom: 2em;
    }
  }

  .video-body {
    padding: 0 10vw 10vw;
  }

  .video-wrapper {
    position: relative;
    background: #fff;
    z-index: 0;
    overflow: hidden;

    .video-item-wrapper {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      scroll-behavior: smooth;
      overflow: hidden;
      width: auto;
      z-index: -1;
      box-shadow: 0 16px 12px #939999;

      video {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: auto;
        width: 100%;
      }
    }

    .video-parallax-wrapper {
      position: relative;
      scroll-behavior: smooth;
      box-shadow: 0 16px 12px #939999;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.1);
        @include transition(background-color 0.4s);
      }

      img {
        width: 100%;
      }
    }

    img {
      display: block;
    }

    .button-play {
      $butHoveDur: 200ms;

      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      background: #fff;
      width: 96px;
      height: 96px;
      margin-top: -48px;
      margin-left: -48px;
      @include b-radius(50%);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include transition(all $butHoveDur);

      stop {
        stop-color: rgb(36, 37, 38);
      }

      stop:nth-child(1) {
        @include transition-delay($butHoveDur / 3 * 4);
      }

      stop:nth-child(2) {
        @include transition-delay($butHoveDur / 3 * 3);
      }

      stop:nth-child(3) {
        @include transition-delay($butHoveDur / 3 * 2);
      }

      stop:nth-child(4) {
        @include transition-delay($butHoveDur / 3);
      }

      stop:nth-child(5) {
        @include transition-delay(0);
      }

      svg {
        padding-left: 10px;
        width: 38px;
        height: 52px;
        pointer-events: none;
      }

      &:hover {
        @include transform(scale(1.025));

        stop:nth-child(1) {
          stop-color: rgb(246, 149, 37);
          @include transition-delay(0.1ms);
        }

        stop:nth-child(2) {
          stop-color: rgb(239, 161, 55);
          @include transition-delay($butHoveDur / 3);
        }

        stop:nth-child(3) {
          stop-color: rgb(231, 133, 73);
          @include transition-delay($butHoveDur / 3 * 2);
        }

        stop:nth-child(4) {
          stop-color: rgb(222, 100, 94);
          @include transition-delay($butHoveDur / 3 * 3);
        }

        stop:nth-child(5) {
          stop-color: rgb(214, 68, 114);
          @include transition-delay($butHoveDur / 3 * 4);
        }

        & + .video-parallax-wrapper::after {
          background-color: rgba(0, 0, 0, 0.7);
          @include transition(background-color 0.4s);
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .video-section {
    .title-part {
      padding: 8vw 10vw 5vw;

      p {
        @include font-size(17px);
      }

      h2 {
        font-size: 3.8vw;
        line-height: 29px;
      }
    }

    .video-wrapper {
      .video-parallax-wrapper,
      .video-item-wrapper {
        height: 350px;

        img {
          width: 110%;
          @include translate(-2%, 0%);
        }
      }

      .button-play {
        width: 56px;
        height: 56px;
        margin-top: -28px;
        margin-left: -28px;

        svg {
          padding-left: 4px;
          width: 32px;
          height: 23px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .video-section {
    .title-part {
      flex-direction: column;
      align-items: flex-start;
      padding: 18vw 4vw 5vw;
      p {
        width: 100%;
        @include font-size(17px);
        line-height: 1.56em;
      }

      h2 {
        margin-bottom: auto;
        font-size: 5.2vw;
        width: 70%;
        line-height: 19px;
      }

      &.ja h2 {
        width: 100%;
        font-size: 2.3vw;
      }
    }

    .video-wrapper {
      .video-parallax-wrapper,
      .video-item-wrapper {
        height: 180px;

        img {
          width: 110%;
          @include translate(-2%, 0%);
        }
      }
    }

    .video-body {
      padding: 0 4vw 10vw;
    }
  }
}
</style>

<template>
    <section class="slider-rows-bg-words" :class="{
        'ja': $i18n.locale == 'ja'
    }">
        <sectionHeader :title="$t('home.digitalTitle')">
            <span v-html="$t('home.digitalSubTitleText')"></span>
        </sectionHeader>
        <div class="slide-row slide-row--first">
            <div class="slide-cel slide-with-content">
                <div class="slide-content">
                    <h3>{{ $t("home.digitalItemTitle") }}</h3>
                    <p>{{ $t("home.digitalItemText") }}</p>
                </div>
                <div class="slide-text">
                    <parallaxContainer>
                        <parallaxItem :isActive="isAnimation" :scrollX="true" :factor="factor">
                            <span>Instagram</span>
                        </parallaxItem>
                    </parallaxContainer>
                </div>
            </div>
            <div class="slide-cel slide-without-content">
                <div class="slide-text">
                    <parallaxContainer>
                        <parallaxItem :isActive="isAnimation" :scrollX="true" :factor="factor">
                            <span>Instagram</span>
                        </parallaxItem>
                    </parallaxContainer>
                </div>
            </div>
        </div>
        <div class="slide-row slide-row--second">
            <div class="slide-cel slide-without-content">
                <div class="slide-text">
                    <parallaxContainer>
                        <parallaxItem :isActive="isAnimation" :scrollX="true" :factor="factorNegative">
                            <span>Golden disc</span>
                        </parallaxItem>
                    </parallaxContainer>
                </div>
            </div>
            <div class="slide-cel slide-with-content">
                <div class="slide-content">
                    <h3>{{ $t("home.digitalItem2Title") }}</h3>
                    <p>{{ $t("home.digitalItem2Text") }}</p>
                </div>
                <div class="slide-text">
                    <parallaxContainer>
                        <parallaxItem :isActive="isAnimation" :scrollX="true" :factor="factorNegative">
                            <span>Golden disc</span>
                        </parallaxItem>
                    </parallaxContainer>
                </div>
            </div>
        </div>
        <div class="slide-row slide-row--third">
            <div class="slide-cel slide-with-content">
                <div class="slide-content">
                    <h3>{{ $t("home.digitalItem3Title") }}</h3>
                    <p>{{ $t("home.digitalItem3Text") }}</p>
                    <router-link to="/youtube#packages" class="button-youtube">
                        <span> {{ $t('home.youtubeButton') }}</span>
                        <svg width="44" height="25" viewBox="0 0 44 25" xmlns="http://www.w3.org/2000/svg"><path d="M38.5 11l-8-8L33 .5 44 12v1L33 24.5 30.5 22l8-8H0v-3h38.5z" fill="#fff" fill-rule="nonzero"></path></svg>
                    </router-link>
                </div>
                <div class="slide-text">
                    <parallaxContainer>
                        <parallaxItem :isActive="isAnimation" :scrollX="true" :factor="factor">
                            <span>YouTube</span>
                        </parallaxItem>
                    </parallaxContainer>
                </div>
            </div>
            <div class="slide-cel slide-without-content">
                <div class="slide-text">
                    <parallaxContainer>
                        <parallaxItem :isActive="isAnimation" :scrollX="true" :factor="factor">
                            <span>YouTube</span>
                        </parallaxItem>
                    </parallaxContainer>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

import parallaxContainer from './../../components/parallax-container'
import parallaxItem from './../../components/parallax-item';
import sectionHeader from './../../components/section-header';

export default {
    data() {
        return {
            isAnimation: false,
            factor: 0.5,
            factorNegative: -0.5
        }
    },
    components: {
        parallaxContainer,
        parallaxItem,
        sectionHeader
    },
    created() {
        window.addEventListener('scroll', this.scrollHandler);
    },
    destroyed() {
        window.removeEventListener('scroll', this.scrollHandler);
    },
    methods: {
        scrollHandler() {
            const el = document.getElementById('home-digital-section');

            const windowTop = window.pageXOffset === undefined ? document.documentElement.scrollTop : window.pageYOffset

            this.isAnimation = el.offsetTop - window.innerHeight / 2 <= windowTop && (el.offsetTop + el.offsetHeight) >= windowTop;
        }
    }
}
</script>
<style lang="scss" scoped>
@import './../../scss/app';

.slider-rows-bg-words {
    position: relative;
    height: auto;
    width: 100%;
    background-color: #1A1B1B;
}

.slide-cel {
    position: absolute;
    height: inherit;
    overflow: hidden;
}

.slide-text {
    position: absolute;
    bottom: 0;
    width:100vw;
    color: #1D1F1F;
    

    .paralax-word-container {
        position: absolute;
        bottom: 0;
        top:0;
        left: 0;
        right: 0;
    }

    span {
        position: absolute;
        bottom: 0;
        font-size: calc(240px + 200 * ((100vw - 1280px) / 1280));
        letter-spacing: -0.02em;
        line-height: 0.45;
        position: absolute;
        user-select: none;
        white-space: nowrap;
        left: 0; 
        font-family: $family-medium;
        font-weight: 600;
    }
}

.button-youtube {
    position: relative;
    color: #DCE6E6;
    font-size: 1.5vw;
    line-height: 1vw;
    font-family: $family-light;
    cursor: pointer;
    z-index: 0;
    padding: 20px 20px 10px;
    margin-top: 20px;
    width: 27vw;
    min-width: 300px;

    &::after {
      content: '';
      position: absolute;
      top:0;
      bottom:0;
      left: 0;
      right: 0;
      background: #fff;
      z-index: 1;
      background-color: #4923D8;
      @include opacity(0);
      @include transition(all 500ms);
    }

     &:hover {
      &:after {
        @include opacity(1);
      }

      svg {
        margin-left: 32px;
      }
    }

    span {
        position: relative;
        display: inline-block;
        z-index: 2;
        font-size: 1.5vw;
        font-family: $family-light;
    }

    svg {
      position: relative;
      width: 25px;
      top: -2px;
      margin-left: 12px;
      display: inline-block;
      z-index: 2;
      @include transition(all 500ms);
    }
}

.slider-rows-bg-words.ja {

    .button-youtube {
      svg {
        position: relative;
        width: 25px;
        top: -2px;
      }
      span {
        font-size: 1.25vw;
      }
    }
  }

  
@media (max-width: 992px) {
    .button-youtube {

      span {
        @include font-size(17px);
      }

        &:hover {
            &:after {
                @include opacity(0);
            }

            svg {
                margin-left: 12px;
            }
        }
    }
    
    .slider-rows-bg-words.ja {

    .button-youtube {
      span {
        @include font-size(17px);
      }

      svg {
        top: -5px;
      }
    }
  }
}


.slide-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: inherit;
    z-index: 10;

     h3 {
        font-size: 16px;
        font-size: calc(16px + 3 * ((100vw - 1280px) / 1280));
        font-family: $family-bold;
        color: #f5f5f5;
        letter-spacing: 0.32em;
        margin-bottom: 1em;
        text-transform: uppercase;
        z-index: 2;
    }

    p {
        font-size: 16px;
        font-size: calc(16px + 11 * ((100vw - 1280px) / 1280));
        color: #939999;
        font-family: $family-light;
        z-index: 2;
        line-height: 1.56em;
    }
}
.ja .slide-content h3 {
    font-size: 17px;
    font-size: calc(17px + 3 * ((100vw - 1280px) / 1280));
    letter-spacing: 0.1em;
    text-transform: none;
    font-weight: 600;
}

.slide-row {
    position: relative;
    width:100%;
    height: 50vh;

    .slide-with-content, .slide-without-content {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;
        width: 55%;
        padding: 0 6vw 0 20vw;
    }

    .slide-without-content {
        padding: 0;
        z-index: 2;
        width: 45%;
    }

    &--first .slide-with-content {
        left: 0;
        right: auto;
        .slide-text {
            left: 14%;
        }
    }

    &--first .slide-without-content {
        background: linear-gradient(#F6BD25, #DE645E);
        right: 0;
        left: auto;
        .slide-text {
            color: #DE645E;
            left: -105%;
        }
    }

    &--second .slide-with-content {
        right: 0;
        left: auto;
        padding: 0 16vw 0 11vw;
        .slide-text {
            left: -73.5%;
        }
    }
    

    &--second .slide-without-content {
        background: linear-gradient(#DE645E, #9033A5);
        left: 0;
        right: auto;
        .slide-text {
            color: #9033A5;
            left: 12%;
        }
    }

    &--third .slide-with-content {
        right: auto;
        left: 0;
        .slide-text {
            left: 28%;
        }
    }
    

    &--third .slide-without-content {
        background: linear-gradient(#9033A5, #4923D8);
        right: 0;
        left: auto;
        .slide-text {
            left: -88%;
            color: #4923D8;
        }
    }
}


@media (max-width: 992px) {
    .slide-content {
        
        h3 {
            @include font-size(16px);
            margin-bottom: 1em;
        }

        p {
            line-height: 1.56em;
            @include font-size(16px);
        }
    }

    .button-youtube{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        color: rgba(0, 0, 0, 1);
        @include font-size(24px);
        line-height: 28px;
        cursor: pointer;
        z-index: 0;
        padding: 13px 20px 13px;
        background-color: #fff;
        width: 100%;

        &::after {
            content: '';
            position: absolute;
            top:0;
            bottom:0;
            left: 0;
            right: 0;
            background: #fff;
            z-index: 1;
            background-color: #4923D8;
            @include opacity(0);
            @include transition(all 500ms);
        }

        &:hover {
            text-decoration: none;
        }

        svg {
            display: none;
        }
     }

    .slide-row {
        height: auto;
        padding-bottom: 80px;
        &--third, &--second, &--first {
            .slide-without-content {
                top:0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                z-index: 1;
            }

            .slide-with-content {
                z-index: 2;
                position: relative;
                top:0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                padding: 2.5vw 5vw;

                .slide-text {
                    display: none;
                }

                .slide-content {
                    position: relative;
                    z-index: 2;
                    background: rgba(25, 26, 26, 0.92);
                    border-radius: 2px;
                    padding: 5vw;
                    width: 100%;
                }
                
            }
        }

        &--first .slide-without-content{
            .slide-text {
               left: -11%;
            }
        }

        &--third .slide-without-content{
            .slide-text {
               left: -13%;
            }
        }
    }
}

</style>
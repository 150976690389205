export const minimumPackage = {
    data() {
        return {
            packageSlug: 'minimum',
            packageTitle: null,
            packagePrice: null,
            packageDescription: null,
            packageDescriptionJa: null,
            packageViewsAmount: null,
        }
    },
    mounted() {
        this.$http({ url: 'packages/slug/' + this.packageSlug, method: 'GET'})
            .then((resp) => { 
                this.packageTitle = resp.data.data.title;
                this.packagePrice = +resp.data.data.price;
                this.packageDescription = resp.data.data.description;
                this.packageDescriptionJa = resp.data.data.description_ja;
                this.packageViewsAmount = parseInt(resp.data.data.views_amount).toLocaleString('ru-RU');
            });
    }
};
<template>
  <div class="bg-animation">
    <div v-if="!isMobile() && !isTablet() && isChrome" class="content--canvas"></div>
  </div>
</template>
<script>
import SimplexNoise from "simplex-noise";

export default {
  data() {
    return {
      isChrome: true,
      animationLink: null,
      TAU: 2 * Math.PI,
      circleCount: 150,
      circlePropCount: 8,
      circlePropsLength: 150 * 8,
      baseSpeed: 0.1,
      rangeSpeed: 1,
      baseTTL: 150,
      rangeTTL: 200,
      baseRadius: 100,
      rangeRadius: 200,
      rangeHue: 60,
      xOff: 0.0015,
      yOff: 0.0015,
      zOff: 0.0015,
      backgroundColor: "hsla(0,0%,5%,1)",

      container: null,
      canvas: null,
      ctx: null,
      circles: null,
      circleProps: null,
      simplex: null,
      baseHue: null,

      mobileWidth: 793,
      tabletWidth: 992,
      colorIndex: 0,
      colors:[44, 44, 42, 42, 40, 40, 35, 35, 30, 30, 23, 23, 14, 14, 3, 3, 351, 351, 341, 341, 331, 331, 320, 320, 306, 306, 289, 289, 267, 267, 253, 253]
    };
  },
  methods: {
    isMobile() {
      return this.getWindowWidth() <= this.mobileWidth;
    },
    isTablet() {
      return (
        this.getWindowWidth() >= this.mobileWidth &&
        this.getWindowWidth() <= this.tabletWidth
      );
    },
    getWindowWidth() {
      return (
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      );
    },
    rand(n) {
      return n * Math.random();
    },
    fadeInOut(t, m) {
      let hm = 0.5 * m;
      return Math.abs(((t + hm) % m) - hm) / hm;
    },
    initCircles() {
      this.circleProps = new Float32Array(this.circlePropsLength);
      this.simplex = new SimplexNoise();
      this.baseHue = 220;
      let i;

      for (i = 0; i < this.circlePropsLength; i += this.circlePropCount) {
        this.initCircle(i);
      }
    },
    initCircle(i) {
      let x, y, n, t, speed, vx, vy, life, ttl, radius, hue;

      x = this.rand(this.canvas.a.width);
      y = this.rand(this.canvas.a.height);
      n = this.simplex.noise3D(
        x * this.xOff,
        y * this.yOff,
        this.baseHue * this.zOff
      );
      t = this.rand(this.TAU);
      speed = this.baseSpeed + this.rand(this.rangeSpeed);
      vx = speed * Math.cos(t);
      vy = speed * Math.sin(t);
      life = 0;
      ttl = this.baseTTL + this.rand(this.rangeTTL);
      radius = this.baseRadius + this.rand(this.rangeRadius);
      hue = n;
      hue = this.getNextColor();

      this.circleProps.set([x, y, vx, vy, life, ttl, radius, hue], i);
    },
    updateCircles() {
      let i;

      this.baseHue++;

      for (i = 0; i < this.circlePropsLength; i += this.circlePropCount) {
        this.updateCircle(i);
      }
    },
    updateCircle(i) {
      let i2 = 1 + i,
        i3 = 2 + i,
        i4 = 3 + i,
        i5 = 4 + i,
        i6 = 5 + i,
        i7 = 6 + i,
        i8 = 7 + i;
      let x, y, vx, vy, life, ttl, radius, hue;

      x = this.circleProps[i];
      y = this.circleProps[i2];
      vx = this.circleProps[i3];
      vy = this.circleProps[i4];
      life = this.circleProps[i5];
      ttl = this.circleProps[i6];
      radius = this.circleProps[i7];
      hue = this.circleProps[i8];

      this.drawCircle(x, y, life, ttl, radius, hue);

      life++;

      this.circleProps[i] = x + vx;
      this.circleProps[i2] = y + vy;
      this.circleProps[i5] = life;

      (this.checkBounds(x, y, radius) || life > ttl) && this.initCircle(i);
    },
    drawCircle(x, y, life, ttl, radius, hue) {
      this.ctx.a.save();
      this.ctx.a.fillStyle = `hsla(${hue},60%,30%,${this.fadeInOut(
        life,
        ttl
      )})`;
      this.ctx.a.beginPath();
      this.ctx.a.arc(x, y, radius, 0, this.TAU);
      this.ctx.a.fill();
      this.ctx.a.closePath();
      this.ctx.a.restore();
    },
    checkBounds(x, y, radius) {
      return (
        x < -radius ||
        x > this.canvas.a.width + radius ||
        y < -radius ||
        y > this.canvas.a.height + radius
      );
    },
    createCanvas() {
      this.container = document.querySelector(".content--canvas");
      this.canvas = {
        a: document.createElement("canvas"),
        b: document.createElement("canvas"),
      };
      this.container.appendChild(this.canvas.b);
      this.ctx = {
        a: this.canvas.a.getContext("2d"),
        b: this.canvas.b.getContext("2d"),
      };
    },
    resize() {
      
      if(this.isMobile() || this.isTablet()) {
        return;
      }

      const { innerWidth, innerHeight } = window;

      this.canvas.a.width = innerWidth;
      this.canvas.a.height = innerHeight;

      this.ctx.a.drawImage(this.canvas.b, 0, 0);

      this.canvas.b.width = innerWidth;
      this.canvas.b.height = innerHeight;

      this.ctx.b.drawImage(this.canvas.a, 0, 0);
    },
    render() {
      this.ctx.b.save();
      this.ctx.b.filter = "blur(50px)";
      this.ctx.b.drawImage(this.canvas.a, 0, 0);
      this.ctx.b.restore();
    },
    draw() {
      this.ctx.a.clearRect(0, 0, this.canvas.a.width, this.canvas.a.height);
      this.ctx.b.fillStyle = this.backgroundColor;
      this.ctx.b.fillRect(0, 0, this.canvas.b.width, this.canvas.b.height);
      this.updateCircles();
      this.render();
      this.animationLink = window.requestAnimationFrame(this.draw);
    },
    setup() {
      if(this.isMobile() || this.isTablet()) {
        return;
      }

      this.createCanvas();
      this.resize();
      this.initCircles();
      this.draw();
    },
    getNextColor() {
      this.colorIndex++;

      if(this.colors[this.colorIndex] === undefined) {
        this.colorIndex = 0;
      }

      return this.colors[this.colorIndex];
    }
  },
  destroyed() {
    window.cancelAnimationFrame(this.animationLink);
  },
  mounted() {
    this.isChrome = (typeof window.chrome == "object");
    
    if(this.isChrome) {
      this.setup();
      window.addEventListener("resize", this.resize);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "./../scss/app";

.content--canvas {
  position: relative;
  background: #000;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.bg-animation {
  min-height: 100vh;
  position: relative;
  background: #000;
  background: -moz-linear-gradient(-45deg, 
    #f6bd25,
    #f2b02b,
    #efa137,
    #eb9540,
    #e37553,
    #de645e,
    #da5468,
    #c53f7f,
    #b43b8b,
    #a23798,
    #9033a5,
    #6d2bbe,
    #4923d8
  );
  background: -webkit-linear-gradient(
    -45deg,
    #f6bd25,
    #f2b02b,
    #efa137,
    #eb9540,
    #e37553,
    #de645e,
    #da5468,
    #c53f7f,
    #b43b8b,
    #a23798,
    #9033a5,
    #6d2bbe,
    #4923d8
  );
  background: linear-gradient(-45deg, 
    #f6bd25,
    #f2b02b,
    #efa137,
    #eb9540,
    #e37553,
    #de645e,
    #da5468,
    #c53f7f,
    #b43b8b,
    #a23798,
    #9033a5,
    #6d2bbe,
    #4923d8
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6bd25,', endColorstr='#4923d8',GradientType=0);
  background-size: 400% 400%;
  @include animation(gradient 15s ease infinite);
}

@include keyframes(gradient) {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 992px) {
  .content--canvas {
    display: none;
  }
}
</style>

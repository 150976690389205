<template>
    <section class="services-section">
        <headerOfSection :title="$t('home.servicesTitle')">
          <span v-html="$t('home.serviceSubTitle')"></span>
        </headerOfSection>
        <div class="services-list">
          <div class="servise-group" :class="{ active: isServiceListShown }">
            <h3 v-on:click="onPlusClick(0)">
              {{ $t("home.awardsList.title") }}
              <span class="status" :class="{ opened: activeTabMobile == 0, closed: activeTabMobile != 0 }">
                <span class="horizontal"></span>
                <span class="vertical"></span>
              </span>
            </h3>
            <ul :class="{'shown': activeTabMobile == 0 || !isMobile(), ja: $i18n.locale == 'ja' }">
              <li>{{ $t("home.awardsList.grammys") }}</li>
              <li>{{ $t("home.awardsList.musicAwards") }}</li>
              <li>{{ $t("home.awardsList.billboard") }}</li>
              <li>{{ $t("home.awardsList.goldAndPlatinum") }}</li>
              <li>{{ $t("home.awardsList.spotify") }}</li>
              <li>{{ $t("home.awardsList.appleMusic") }}</li>
            </ul>
          </div>
          <div class="servise-group" :class="{ active: isServiceListShown }">
            <h3 v-on:click="onPlusClick(1)">
              {{ $t("home.brandingList.title") }}
              <span class="status" :class="{ opened: activeTabMobile == 1, closed: activeTabMobile != 1 }">
                <span class="horizontal"></span>
                <span class="vertical"></span>
              </span>
            </h3>
            <ul :class="{'shown': activeTabMobile == 1 || !isMobile(), ja: $i18n.locale == 'ja' }">
              <li>{{ $t("home.brandingList.dataResearch") }}</li>
              <li>{{ $t("home.brandingList.branding") }}</li>
              <li>{{ $t("home.brandingList.businessConsulting") }}</li>
              <li>{{ $t("home.brandingList.market") }}</li>
              <li>{{ $t("home.brandingList.mediaSelection") }}</li>
              <li>{{ $t("home.brandingList.videoCreation") }}</li>
            </ul>
          </div>
          <div class="servise-group" :class="{ active: isServiceListShown }">
            <h3 v-on:click="onPlusClick(2) || !isMobile()">
              {{ $t("home.promotionList.title") }}
              <span class="status" :class="{ opened: activeTabMobile == 2, closed: activeTabMobile != 2 }">
                <span class="horizontal"></span>
                <span class="vertical"></span>
              </span>
            </h3>
            <ul :class="{'shown': activeTabMobile == 2 || !isMobile(), ja: $i18n.locale == 'ja' }">
              <li>{{ $t("home.promotionList.boost") }}</li>
              <li>
                <router-link to="/youtube" class="youtube-page-link">
                  {{ $t("home.promotionList.youtube") }}
                </router-link>
              </li>
              <li>{{ $t("home.promotionList.fb") }}</li>
              <li>{{ $t("home.promotionList.instagram") }}</li>
              <li>{{ $t("home.promotionList.twitter") }}</li>
              <li>{{ $t("home.promotionList.promotion") }}</li>
            </ul>
          </div>
          <div class="servise-group" :class="{ active: isServiceListShown }">
            <h3 v-on:click="onPlusClick(3)">
              {{ $t("home.marketingList.title") }}
              <span class="status" :class="{ opened: activeTabMobile == 3, closed: activeTabMobile != 3 }">
                <span class="horizontal"></span>
                <span class="vertical"></span>
              </span>
            </h3>
            <ul :class="{'shown': activeTabMobile == 3 || !isMobile(), ja: $i18n.locale == 'ja' }">
              <li>{{ $t("home.marketingList.matching") }}</li>
              <li>{{ $t("home.marketingList.development") }}</li>
              <li>{{ $t("home.marketingList.nurturing") }}</li>
              <li>{{ $t("home.marketingList.features") }}</li>
              <li>{{ $t("home.marketingList.remixes") }}</li>
              <li>{{ $t("home.marketingList.parties") }}</li>
            </ul>
          </div>
        </div>
        <actionLink :questionText="$t('actionQuestion')" :linkText="$t('actionBtnText')" linkHref="/services" />
    </section>
</template>
<script>
import actionLink from './../../components/action-block';
import headerOfSection from './../../components/section-header';

export default {
    data() {
        return {
            isServiceListShown: false,
            windowOffsetTop: 0,
            pageHeight: 0,
            activeTabMobile: -1
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
        const body = document.body, html = document.documentElement;

        this.pageHeight = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
    },
    methods: {
        handleScroll() {
          this.windowOffsetTop = window.pageXOffset === undefined ? document.documentElement.scrollTop : window.pageYOffset;
          
          if(this.isServiceListShown)
            window.removeEventListener('scroll', this.handleScroll);

          this.isServiceListShown = this.getPers(this.windowOffsetTop) > this.getServicesListTriggerOffset() - 5;
        },
        getPers(height) { 
            return height / (this.pageHeight / 100)
        },
        getServicesListTriggerOffset() {
            return this.getPers(document.querySelector(".services-section").offsetTop);
        },
        onPlusClick(index) {
          if(index == this.activeTabMobile) {
            this.activeTabMobile = -1;
          } else {
            this.activeTabMobile = index;
          }
        },
        isMobile() {
            return (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 576;
        }
    },
    components: {
      actionLink,
      headerOfSection
    }
}
</script>
<style lang="scss" scoped>
@import './../../scss/app';

.services-section {
  position: relative;
  background-color: #1D1F1F;
}

.services-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5vw 15vw;
  flex-wrap: nowrap;
}

.servise-group {
  $serviseGropFadeInDuration: 800ms;
  width: 25%;
  color: #F5F5F5;
  @include opacity(0);
  @include transition(opacity $serviseGropFadeInDuration);

  &.active {
    @include opacity(1);

    &:nth-child(2) {
      @include transition-delay($serviseGropFadeInDuration / 4);
    }

    &:nth-child(3) {
      @include transition-delay($serviseGropFadeInDuration / 4 * 2);
    }

    &:nth-child(4) {
      @include transition-delay($serviseGropFadeInDuration / 4 * 3);
    }
  }
        
  h3 {
    position: relative;
    @include font-size(23px);
    font-family: $family-medium;
    font-size: calc(21px + 4 * ((100vw - 1280px) / 640));
    line-height: calc(21px + 4 * ((100vw - 1280px) / 640));
    font-weight: 400;
    color: #F5F5F5;
  }

  ul {
    margin: 24px 0 32px;
  }

  li {
    display: block;
    padding: 0.5em 0;
    color: #939999;
    font-weight: 400;
    font-family: $family-regular;
    line-height: 1.4em;
    @include font-size(17px);
    font-size: calc(15px + 3 * ((100vw - 1160px) / 760));
  }
}

.youtube-page-link {
  position:relative;
  color: #fff;
      text-decoration: underline;

  span {
    position: relative;
    display: inline-block;
    width:12px;
    height: 1.5px;
    background:  rgb(73, 35, 216);
    @include rotate(45deg);
    top: -10px;
  }

  &:hover {
    color: rgb(73, 35, 216);
    text-decoration: underline;
  }
}

.status {
  position: absolute;
  top:5px;
  right: 0;
  display: none;
  width: 15px;
  height: 15px;

  &.closed {
    .vertical {
      @include transition(all 0.5s ease-in-out);
      @include rotate(-90deg);
    }
    
    .horizontal {
      -webkit-transition: -webkit-transform 0.5s;
      @include transition(all 0.5s ease-in-out);
      @include rotate(-90deg);
      @include opacity(1);
    }
  }

  &.opened {
    @include opacity(1);
    
    .vertical {
      -webkit-transition: -webkit-transform 0.5s;
      @include transition(all 0.5s ease-in-out);
      @include rotate(90deg);
    }
                    
    .horizontal {
      -webkit-transition: -webkit-transform 0.5s;
      @include transition(all 0.5s ease-in-out);
      @include rotate(90deg);
      @include opacity(0);
    }
  }

  .horizontal {
    position: absolute;
    background-color: #fff;
    width: 15px;
    height: 2px;
    left: 50%;
    margin-left: -8px;
    top: 50%;
    margin-top: -1px;
    @include rotate(-90deg);
    @include opacity(1);
  }

  .vertical {
    position: absolute;
    background-color: #fff;
    width: 2px;
    height: 15px;
    left: 50%;
    margin-left: -1px;
    top: 50%;
    margin-top: -8px;
    @include rotate(-90deg);
    @include opacity(1);
  }
}


@media (max-width: 992px) {

  .services-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5vw 10vw;
  }

  .servise-group {
    width: 50%;
    padding: 0 0 34px;
    @include opacity(1);

    li {
      margin: 18px 0;
    }
  }
} 

@media (max-width: 576px) {

  .services-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5vw 10vw;
  }

  .youtube-page-link {
    span {
      position: relative;
      display: inline-block;
      width:8px;
      height: 1px;
      background: #939999;
      @include rotate(45deg);
      top: -7px;
    }
  }

  .servise-group {
    width: 100%;

    ul {
      display: flex;
      flex-wrap: wrap;
      margin:0; 
      @include animation(hide-list .4s ease);
      @include animation-fill-mode(both);
      overflow: hidden;
      
      &.shown {
        @include animation(show-list .4s ease);
        @include animation-fill-mode(both);
      }
    }

    ul.ja {
      @include animation(hide-list-ja .4s ease);
      @include animation-fill-mode(both);
      
      &.shown {
        @include animation(show-list-ja .4s ease);
        @include animation-fill-mode(both);
      }
    }
      
    li {
      width: 50%;
      @include font-size(13px);
      margin: 0;

      &:nth-child(1), &:nth-child(2) {
        padding-top: 25px; 
      }
    }
  }

  .status {
    display: block;
  }
}

@include keyframes(show-list) {
  from {
    height: 0px;
  }
  to {
    height: 100px;
  }
}

@include keyframes(hide-list) {
  from {
    height: 100px;
  }
  to {
    height: 0px;
  }
}

@include keyframes(show-list-ja) {
  from {
    height: 0px;
  }
  to {
    height: 150px;
  }
}

@include keyframes(hide-list-ja) {
  from {
    height: 150px;
  }
  to {
    height: 0px;
  }
}

</style>
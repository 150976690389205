<template>
  <div class="brfore-after-carousel-wrapper">
    <div class="view-port">
       <div
        class="slide-container"
        :class="{ comming: i === active, leaving: i === leaving }"
        v-for="(img, i) in images"
        :key="i"
      >
        <div class="bg-image" :style="'background-image: url(' + img.src + ')'"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: this.images.length - 1,
      leaving: null,
      animation: null
    };
  },
  props: {
    images: {
      default: new Array(),
      type: Array,
    },
  },
  methods: {
    next() {
      let next = this.active + 1;

      if (this.images[this.active + 1] === undefined) {
        next = 0;
      }

      this.leaving = this.active;
      this.active = next;
    },
  },
  created() {
    this.animation = setInterval(() => this.next(), 5000)
  },
  destroyed() {
    clearInterval(this.animation);
  },
  mounted() {
    this.next();
  },
};
</script>
<style lang="scss" scoped>
@import "./../scss/app";

@mixin skewAndTranslate($x, $y, $a, $b) {
  -webkit-transform: skew($x, $y) translate($a, $b);
  -moz-transform: skew($x, $y) translate($a, $b);
  -ms-transform: skew($x, $y) translate($a, $b);
  -o-transform: skew($x, $y) translate($a, $b);
  transform: skew($x, $y) translate($a, $b);
}

.brfore-after-carousel-wrapper {
  position: relative;
  height: inherit;
  width: 100vw;
  left: -10%;
  @include skew(-6deg, 0);
  overflow: hidden;
}

.view-port {
  position: relative;
  height: inherit;
  left: -20%;
  @include skew(6deg, 0);
}

$dur: .4s;

.slide-container {
  overflow:hidden;
  position: absolute;
  top: 0;
  left: -100%;
  right: 100%;
  height: inherit;
  overflow: hidden;

  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.comming {
    left: 0%;
    right: 0%;
    z-index: 2;
    @include transition(all linear $dur);

    .bg-image {
      @include skewAndTranslate(0deg, 0, 3%, 0);
      @include transition(all linear 5s);
    }
  }

  &.leaving {
    left: 0%;
    right: 0%;
    z-index: 1;
    .bg-image {
      @include skewAndTranslate(0deg, 0, 3%, 0);
    }
  }
}

@media (max-width: 992px) {
  .brfore-after-carousel-wrapper {
    width:120vw;
  }

  .view-port {
    left: 0;
  }
}
</style>

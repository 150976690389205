import { render, staticRenderFns } from "./services.vue?vue&type=template&id=280c4380&scoped=true&"
import script from "./services.vue?vue&type=script&lang=js&"
export * from "./services.vue?vue&type=script&lang=js&"
import style0 from "./services.vue?vue&type=style&index=0&id=280c4380&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "280c4380",
  null
  
)

export default component.exports
<template>
  <div class="home-carousel">
    <div class="content-left">
      <div class="content-text">
        <h2 class="title">{{ $t("home.headerTitle") }}</h2>
        <h3 class="sub-title">{{ $t("home.headerSubTitle") }}</h3>
        <p class="text">
          <b>{{ $t("home.headerTextTitle") }}</b>
          {{ $t("home.headerText") }}
        </p>
      </div>
      <animation class="canvas-animation" />
    </div>
    <div class="content-right">
      <carousel :images="sliderImages" />
    </div>
  </div>
</template>
<script>
import carousel from "./../../components/before-after-carousel";
import animation from "./../../components/background-animation";

export default {
  data() {
    return {
      sliderImages: [
        {
          src: "images/home/slider/1.jpg",
          alt: "beverly heals",
        },
        {
          src: "images/home/slider/2.jpg",
          alt: "disco-ball",
        },
        {
          src: "images/home/slider/3.jpg",
          alt: "holly-wood",
        },
      ],
    };
  },
  components: {
    carousel,
    animation,
  },
};
</script>
<style lang="scss" scoped>
@import "./../../scss/app";

.home-carousel {
  display: flex;
  height: 100vh;
}

.content {
  &-left {
    position: relative;
    width: 55%;
  }

  &-right {
    height: inherit;
    width: 45%;
  }
}

.content-text {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.title,
.sub-title,
.text {
  color: #fff;
  width: 60%;
  z-index: 1;
}

.title {
  font-size: 4.7vw;
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 20px;
  white-space: nowrap;
}

.sub-title {
  position: relative;
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 103px;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    width: 130px;
    height: 1px;
    background-color: #fff;
    margin: 40px 0;
    transform: translate(0, 8px);
    transition: 1s ease 1.9s;
  }
}

.text {
  font-size: 16px;
  font-family: $family-light;
  b {
    font-family: $family-bold;
    display: block;
    font-size: 19px;
    margin-bottom: 16px;
  }
}

@media (max-width: 992px) {
  .home-carousel {
    display: flex;
    height: 100%;
    flex-direction: column-reverse;
    overflow: hidden;
  }

  .content {
    &-left {
      position: relative;
      top: -10%;
      height: 48vh;
      width: 100%;
      z-index: 2;
    }

    &-right {
      height: 60vh;
      width: 120vw;
      right: 0;
      z-index: 1;
    }
  }

  .content-text {
    justify-content: flex-start;
    top: 4vh;
  }

  .canvas-animation {
    top: -9%;
    @include skew(0, -6deg);
  }
}

@media (max-width: 576px) {
  .home-carousel {
    display: flex;
    height: 100%;
    flex-direction: column-reverse;
    overflow: hidden;
  }

  .content {
    &-left {
      position: relative;
      top: -10%;
      height: 52vh;
      min-height: 400px;
      width: 140%;
      z-index: 2;
    }

    &-right {
      height: 70vh;
      width: 100%;
      z-index: 1;
    }
  }

  .canvas-animation {
    top: -10%;
    @include skew(0, -6deg);
    min-height: 1000px;
  }

  .content-text {
    align-items: flex-start;
    justify-content: flex-start;
    top: 4vh;
  }

  .title,
  .sub-title,
  .text {
    color: #fff;
    width: 100%;
    z-index: 1;
    padding: 0 5vw;
  }

  .title {
    font-size: 40px;
    line-height: 1.2;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .sub-title {
    position: relative;
    font-size: 23px;
    font-weight: 400;
    margin-bottom: 50px;

    &::after {
      content: "";
      position: absolute;
      left: 5vw;
      display: block;
      width: 130px;
      height: 1px;
      background-color: #fff;
      margin: 15px 0;
      transform: translate(0, 8px);
    }
  }

  .text {
    font-size: 15px;
    width: 70%;
    b {
      display: block;
      font-size: 15px;
      margin-bottom: 16px;
    }
  }
}
</style>
